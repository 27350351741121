// IMPORTS
@import (reference) "../settings.less";

// PILLS
#pills-container {
    margin-bottom: 24px;
    margin-top: -12px;

    &:empty {
        display: none;
    }
    
    &:not(:empty) {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        width: 100%;
        gap: 20px;
    }
}

.pills-group {

    &:before {
        content: attr(data-pills-group-name) + ':';
        color: @header-color;
        font-size: 12px;
        font-weight: 700;
        display: block;
    }
}

.single-pill {
    background-color: @light-bg;
    float: left;
    display: flex;
    margin: 4px 4px 0 0;
    align-items: center;
    font-size: 14px;
    padding: 3px 10px;
    cursor: pointer;
    border-radius: var(--borderRadius);

    &:after {
        content: '\e803';
        font-family: icomoon;
        font-size: 9px;
        margin-left: 8px;
    }

    &:nth-of-type(1) {
        clear: left;
    }

    &:last-of-type {
        margin-right: 0;
    }

    &:hover {
        background-color: @border-color;
    }
}

.remove-all-filters {
    padding: 3px 0;
    cursor: pointer;
    color: @header-color;
    font-size: 14px;
    font-weight: 700;
    display: flex;
    align-items: center;

    &:before {
        content: '\e803';
        font-family: icomoon;
        font-weight: normal;
        font-size: 9px;
        margin-right: 8px;
    }

    &:hover {
        color: @error-color;
    }
}

// PAGINATION
.pagination {
    display: flex;
    justify-content: center;
    gap: 40px;

    a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        background-color: white;
        border: 1px solid @border-color;
        border-radius: var(--borderRadius);
        color: @header-color;
    }

    > a {

        &.pagination-prev:after {
            transform: rotate(90deg);
        }

        &.pagination-next:after {
            transform: rotate(-90deg);
        }

        &:after {
            content: '\e915';
            font-family: icomoon;
            font-size: 14px;
        }
    }

    ul {
        display: flex;
        gap: 4px;
    }

    li {
        display: none;

        @media (min-width: 601px) {
            display: block;
        }

        &:first-child,
        &.selected,
        &.prev-to-selected,
        &.next-to-selected,
        &:last-child {
            display: block;
        }

        &:nth-child(2):not(.next-to-selected):not(.selected):not(.prev-to-selected),
        &:nth-last-child(-n+2):not(:last-child):not(.next-to-selected):not(.selected):not(.prev-to-selected) {
            position: relative;
            display: block;

            &:before {
                content: '...';
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: white;
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: flex-end;
                font-size: 22px;
            }
        }
    }

    li.selected > a,
    a:hover {
        color: white;
        background-color: @sec-color;
        border-color: @sec-color;
    }
}